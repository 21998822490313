<div class="container container-xl main-body">
  <header class="row row-gap-3 header">
    <div class="col-md-6 text-center text-md-start">
      <img src="../assets/images/logo-faithful-fashions.png">
    </div>
    <div class="col-md-6 d-flex justify-content-center justify-content-md-end align-items-center social">
      <a href="https://www.pinterest.com/faithfulfashions/" target="_blank"><i class="fa-brands fa-2x fa-pinterest" aria-hidden="true"></i></a>
      <a href="https://www.youtube.com/@FaithfulFashions" target="_blank"><i class="fa-brands fa-2x fa-youtube" aria-hidden="true"></i></a>
    </div>
  </header>
  <div class="row">
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="row newsletter">
    <div class="col-md-6">
      <h2>
        <span class="line1">Can we share</span>
        <span class="line2">New shirts we find</span>
        <span class="line3">with you?</span>
      </h2>
    </div>
    <div class="col-md-6">
      <p>We’re constantly looking for new Christian T-Shirts. Sign up to be notified when we find amazing new shirts!</p>
      <div id="mc_embed_shell">
        <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
        
        <div id="mc_embed_signup">
          <form
            action="https://gmail.us12.list-manage.com/subscribe/post?u=be57d08276242a6a79dcd6cdc&amp;id=faf985340b&amp;f_id=00b34de0f0"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
            target="_blank">
            <div id="mc_embed_signup_scroll">
              
              <div class="mc-field-group">
                <div class="row">
                  <div class="col-md-6"><label for="mce-EMAIL" class="color-teal">Enter your Email Address <span class="asterisk">*</span></label></div>
                  <div class="col-md-6"><div class="indicates-required"><span class="asterisk">*</span> indicates required</div></div>
                </div>
                
                <input type="email" name="EMAIL" class="required email"
                  id="mce-EMAIL" required="" value=""><span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span></div>
              <div id="mce-responses" class="clear foot">
                <div class="response" id="mce-error-response" style="display: none;"></div>
                <div class="response" id="mce-success-response" style="display: none;"></div>
              </div>
              <div aria-hidden="true" style="position: absolute; left: -5000px;">
                /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
                <input type="text" name="b_be57d08276242a6a79dcd6cdc_faf985340b" tabindex="-1" value="">
              </div>
              <div class="optionalParent">
                <div class="clear foot">
                  <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe">
                  <!-- <p style="margin: 0px auto;"><a href="http://eepurl.com/iGQPuE"
                      title="Mailchimp - email marketing made easy and fun"><span
                        style="display: inline-block; background-color: transparent; border-radius: 4px;"><img
                          class="refferal_badge"
                          src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                          alt="Intuit Mailchimp"
                          style="width: 220px; height: 40px; display: flex; padding: 2px 0px; justify-content: center; align-items: center;"></span></a>
                  </p> -->
                </div>
              </div>
            </div>
          </form>
        </div>
        <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
        <script
          type="text/javascript">(function ($) { window.fnames = new Array(); window.ftypes = new Array(); fnames[0] = 'EMAIL'; ftypes[0] = 'email'; fnames[1] = 'FNAME'; ftypes[1] = 'text'; fnames[2] = 'LNAME'; ftypes[2] = 'text'; fnames[3] = 'ADDRESS'; ftypes[3] = 'address'; fnames[4] = 'PHONE'; ftypes[4] = 'phone'; fnames[5] = 'BIRTHDAY'; ftypes[5] = 'birthday'; }(jQuery)); var $mcj = jQuery.noConflict(true);</script>
      </div>

    </div>
  </div>
  <footer class="row footer">
    <div class="col">
      Some of the links you'll find on this site are affiliate links. That just means, basically, if you click on one of those links and end up buying something, we might get a small commission - at no cost to you. These little commissions help to fund our little website. Thanks for supporting us!
    </div>
  </footer>
</div>