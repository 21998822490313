<div class="card shadow">
    <img src="{{product.imageUrl}}" class="card-img-top object-fit-fill" title="{{product.description}}" alt="{{product.description}}">
    <div class="card-body">
        <h2 class="card-title">{{product.title}} <span *ngIf="product.brand !== ''"> by {{product.brand}}</span></h2>
        <p class="card-text">{{product.description | slice:0:truncateAt }}<span *ngIf="product.description.length >= truncateAt-2">...</span></p>
        <a href="{{product.productUrl}}" target="_blank" class="btn-pill" role="button">
            View on
            <img *ngIf="product.availableAtId === Vendors.Amazon" src="../../../assets/images/logo-amazon.png" title="Amazon.com">
        </a>
    </div>
</div>