import { Categories } from '../models/enums/categories.enum';

export const Products = [
    {
        id: 1,
        brand: 'Faithful Fashions',
        title: "Let Your Light Shine - Christian Inspired Design Premium T-Shirt",
        description: "Our 'Let your Light Shine' design radiates positivity, drawing from Matthew 5:16's call to illuminate the world with goodness. Embrace this empowering message and spread God's light wherever you go. Crafted with premium materials, this design blends comfort with a profound reminder of your divine purpose. 'In the same way, let your light shine before others, that they may see your good deeds and glorify your Father in heaven.'",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: 'https://m.media-amazon.com/images/I/911zBRYrPvL._CLa%7C2140%2C2000%7C71ca4UJXlAL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_SX679_.png',
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: 'https://amzn.to/4aG2hZC',
    },
    {
        id: 2,
        brand: 'Faithful Fashions',
        title: "Blessed By Faith - Christian Cross Premium T-Shirt",
        description: "Our 'Blessed' Cross design embodies Ephesians 1:3, celebrating blessings and faith - 'All praise to God, the Father of our Lord Jesus Christ, who has blessed us with every spiritual blessing in the heavenly realms because we are united with Christ.' Embrace divine grace with this beautiful design, fusing style with spirituality. The symbolic cross, adorned with the word 'blessed', echoes the abundant grace in our lives. Crafted with premium fabric, this shirt ensures both comfort and meaningful expression of gratitude.",
        categoryIds: [Categories.Womens, Categories.Mens], // Women's, Men's, etc.
        imageUrl: 'https://m.media-amazon.com/images/I/91IM87eeuCL._CLa%7C2140%2C2000%7C71J9lV10WRL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_SX679_.png',
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: 'https://amzn.to/3NJzsBZ',
    },
    {
        id: 3,
        brand: 'Faithful Fashions',
        title: "It's God... Period. Faith Inspired Design Premium T-Shirt",
        description: "Our 'God.’ design encapsulates the omnipotence and reverence for the divine, echoing Psalm 46:10. “Be still, and know that I am God! I will be honored by every nation. I will be honored throughout the world.” This minimalist yet powerful design serves as a constant reminder that He is God… period. Crafted from premium materials, it ensures both comfort and a profound statement of faith.",
        categoryIds: [Categories.Womens, Categories.Mens, Categories.Childrens], // Women's, Men's, etc.
        imageUrl: 'https://m.media-amazon.com/images/I/B1uthYnkRPS._CLa%7C2140%2C2000%7C51JVhdh7J5L.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_SX679_.png',
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: 'https://amzn.to/3H4LvpM',
    },
    {
        id: 4,
        brand: 'Faithful Fashions',
        title: "Christian Cross - Inspirational Premium T-Shirt",
        description: "Embrace the timeless symbol of faith with our simple 'Christian Cross' design, echoing Galatians 2:20. 'My old self has been crucified with Christ. It is no longer I who live, but Christ lives in me. So I live in this earthly body by trusting in the Son of God, who loved me and gave himself for me.' This elegantly designed tee captures the essence of redemption and spiritual renewal. Crafted from premium materials, it offers both comfort and a meaningful expression of your unwavering faith.",
        categoryIds: [Categories.Womens, Categories.Mens, Categories.Childrens], // Women's, Men's, etc.
        imageUrl: 'https://m.media-amazon.com/images/I/A1mzE4NSO+L._CLa%7C2140%2C2000%7C31BNPf50dHL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_SX679_.png',
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: 'https://amzn.to/48z7YXC',
    },
    {
        id: 5,
        brand: 'Faithful Fashions',
        title: "The Power of Prayer - Christian Inspirational Premium T-Shirt",
        description: "Elevate your faith journey with our 'Power of Prayer' design, drawing inspiration from Jesus in Matthew 7:7. 'Keep on asking, and you will receive what you ask for. Keep on seeking, and you will find. Keep on knocking, and the door will be opened to you.' The intricate cross design embodies the scripture's call to prayer and persistence. This design is a powerful reminder to seek God earnestly, embracing the promise of divine guidance and answers through prayer. Faith is an action and we must take action by praying.",
        categoryIds: [Categories.Womens, Categories.Mens, Categories.Childrens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/B1uthYnkRPS._CLa%7C2140%2C2000%7C51wj2dcgQpL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_SX679_.png",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/3NHNkg4",
    },
    {
        id: 6,
        brand: 'Faithful Fashions',
        title: "The Way, The Truth, The Life - Jesus - Christian Inspired Premium T-Shirt",
        description: "Demonstrate your faith with our 'The Way, The Truth, The Life - Jesus' Christian-Inspired clothing. Reflecting John 14:6 - 'I am the way, the truth, and the life. No one can come to the Father except through me.', these shirts affirm your faith in the teachings of Jesus Christ. Comfy, stylish, and spiritually uplifting, these items spread the message of love and salvation. Wear your devotion proudly!",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/B1uZF4RcdIS._CLa%7C2140%2C2000%7C714wN4fBVuL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_SX679_.png",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/3vkyk1g",
    },
    {
        id: 7,
        brand: 'SUTEFAYECO',
        title: "Faith Can Move Mountains - Christian Inspired Shirt",
        description: "Subtle & Evocative: Move mountains with a mustard seed. Matthew 17:20 inspires this classic tee. Let faith guide your journey.",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/71T+xvHlibL._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/47gGapP",
    },
    {
        id: 8,
        brand: 'Cherished Girl',
        title: "Live by Grace Grow by Faith Walk in Love",
        description: "Let faith bloom in this sweet “Live & Grow” Cherished Girl Tee in Dark Heather. You don’t have to be the best or brightest, the prettiest or most popular or internet famous. Follow the Lord and you will bloom and thrive in grace, faith, and love, because of his many blessings for you! Jesus didn’t die to save the crowd. He died to save unworthy sinners like you and me, and your neighbor, and your lunch buddy at work. He died to save those of us who choose to accept His sacrifice on the cross, ask Him into our hearts, and follow Him all the way into eternity. The Good News about Jesus is a message worth sharing on a T-shirt, and out loud. When we live and grow in the Lord, we just can’t help but pass it on!",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/71TCRHvGoVS._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/3vkyAxg",
    },
    {
        id: 9,
        brand: 'Cherished Girl',
        title: "Light Shine Bright - Inspirational T-Shirt",
        description: "Sparkling & Encouraging: Be a beacon in darkness. 'Shine like the stars in the sky' and light up the world with your faith.",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/61LiyRNFPTS._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/3RLdAY4",
    },
    {
        id: 10,
        brand: 'Trendz',
        title: "Faith - Christian Short Sleeve Graphic T-Shirt",
        description: "Faith Meets Fashion: Embrace your faith with our Christian T-Shirts — symbols of devotion, scripture-inspired designs, and uplifting messages.",
        categoryIds: [Categories.Mens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/61YUrM9DJpL._AC_SX569_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/4aDoZBQ",
    },
    {
        id: 11,
        brand: '',
        title: "I Am Inspiration Tie Dye Bible Verse Christian Religious T-Shirt",
        description: "I Am Inspiration Bible Verse shirt is a Christian quote and great Easter gift for Christian women, moms, daughters, kids who celebrate the resurrection of the God Jesus.",
        categoryIds: [Categories.Womens, Categories.Childrens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/A1XSsTFkLlL._CLa%7C2140%2C2000%7C71%2BLMaoDCAL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_SX679_.png",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/3verWsa",
    },
    {
        id: 12,
        brand: 'Lotucy',
        title: "Love Like Jesus Inspirational Women's Christian Graphic T-Shirt",
        description: "This cute Christian short sleeve T-Shirt is great for daily, going out, casual wear and great for all year. This t-shirt will make you feel confident and charming.",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/718sGOiDxNL._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/41JWsqj",
    },
    // New as of 02/01/24
    {
        id: 13,
        brand: "Love in Faith",
        title: "Fruit of The Spirit Graphic Christian T-Shirt",
        description: "Embrace the virtues of love, joy, and peace with this 'Fruit of the Spirit' shirt, inspired by Galatians 5:22. Wear your faith with style and grace.",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/71Y+E9g7J0L._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/48ZQKU2", 
    },
    {
        id: 14,
        brand: "Love in Faith",
        title: "Pray On It - Camo Christian Graphic T-Shirt",
        description: "Let this 'Pray On It' shirt be a reminder, inspired by Philippians 4:6, to seek God's guidance in every situation. Find peace through prayer.",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/71WtrqqpXgL._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/3HFKt3N", 
    },
    {
        id: 15,
        brand: "Dauocie",
        title: "Way Maker Christian Shirt",
        description: "Embrace divine comfort with this shirt, inspired by God's promises. A tribute to the Almighty - Way Maker, Miracle Worker, Promise Keeper, Light in The Darkness.",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/61BIXUNDPwL._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/3Uq3zSV", 
    },
    {
        id: 16,
        brand: "ASTANFY",
        title: "Be Still and Know Inspirational Christian T-Shirt",
        description: "Find solace in this shirt, echoing Psalm 46:10. Embrace tranquility, knowing God's presence is your refuge.",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/819ZvIBAb3L._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/4824GeS", 
    },
    {
        id: 17,
        brand: "Duduvie",
        title: "His Grace is Enough Christian Faith T-Shirt",
        description: "Embrace unwavering trust with this shirt, echoing Matthew 9:22. Let His faith be your strength, a reminder that with God, all things are possible.",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/71oz-2Czv4L._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/3UpKLmJ", 
    },
    {
        id: 18,
        brand: "Trenz",
        title: "Jesus Over Everything",
        description: "Prioritize the divine with this shirt, inspired by Matthew 6:33. Declare your allegiance to Jesus, placing Him above all else.",
        categoryIds: [Categories.Mens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/61hVv-YoXVL._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/49lbzJo", 
    },
    {
        id: 19,
        brand: "Cherished Girl",
        title: "God is Love Shirt - 1 John 4:8 Inspired Christian Apparel",
        description: "Embrace divine love with this shirt, echoing 1 John 4:8 - Whoever does not love does not know God, because God is love. Let your attire reflect the eternal truth that God is love.",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/61JZL-oo27S._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/3VqQedO", 
    },
    {
        id: 20,
        brand: "Cherished Girl",
        title: "I can do all things through Christ - Philippians 4:13 Inspired Apparel",
        description: "Declare strength through faith with this shirt inspired by Philippians 4:13. Find strength in Christ for all endeavors!",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/71FlhEbip4S._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/4a05oeh", 
    },
    {
        id: 21,
        brand: "Kerusso",
        title: "Walk by Faith Not by Sight Shirt",
        description: "Step confidently with this shirt, inspired by 2 Corinthians 5:7 - 'For we live by faith, not by sight'. Embrace faith's guidance over earthly perceptions.",
        categoryIds: [Categories.Womens, Categories.Mens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/71r-BWXKpXL._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/4ak45GR", 
    },
    {
        id: 22,
        brand: "",
        title: "Jesus: The Way, Truth, Life Shirt - John 14:6 Inspired Christian T-Shirt",
        description: "Embrace Christ's divine guidance with this shirt, inspired by John 14:6. Walk in His truth and light as the way to abundant life.",
        categoryIds: [Categories.Womens, Categories.Mens, Categories.Childrens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/A13usaonutL._CLa%7C2140%2C2000%7C813bhip2q7L.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_SX679_.png",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/43qYDzM", 
    },
    {
        id: 23,
        brand: "",
        title: "God is Good Christian T-Shirt",
        description: "Celebrate God's goodness with this shirt, inspired by Psalm 136:1 - Give thanks to the Lord, for he is good. His love endures forever.",
        categoryIds: [Categories.Womens, Categories.Mens, Categories.Childrens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/A1+Yj8XmdJL._CLa%7C2140%2C2000%7C617seTDVhvL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_SX679_.png",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/43vd1qD", 
    },
    {
        id: 24,
        brand: "Evertree Clothing",
        title: "Y'all Need Jesus Christian Shirt",
        description: "Spread the message of salvation with humor and truth. Let Jesus be the answer to every need.",
        categoryIds: [Categories.Womens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/818xD6VWwDL._AC_SX679_.jpg",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/43sQ9Z5", 
    },
    {
        id: 25,
        brand: "",
        title: "Faith Cross Minimalist Christian Shirt",
        description: "Let this simple cross design inspire unwavering trust.",
        categoryIds: [Categories.Womens, Categories.Mens, Categories.Childrens], // Women's, Men's, etc.
        imageUrl: "https://m.media-amazon.com/images/I/A1vAh9jhIlL._CLa%7C2140%2C2000%7C41WAro9n-DL.png%7C0%2C0%2C2140%2C2000%2B0.0%2C0.0%2C2140.0%2C2000.0_AC_SX522_.png",
        availableAtId: 1,  // Amazon, Etsy, etc. 
        productUrl: "https://amzn.to/3THnEU7", 
    }

    // {
    //     id: 5,
    //     brand: "",
    //     title: "",
    //     description: "",
    //     categoryIds: [Categories.Womens, Categories.Mens, Categories.Childrens], // Women's, Men's, etc.
    //     imageUrl: "",
    //     availableAtId: 1,  // Amazon, Etsy, etc. 
    //     productUrl: "", 
    // },
];