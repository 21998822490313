import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import { Vendors } from 'src/app/models/enums/vendors.enum';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() product: Product;

  // Enums
  Vendors = Vendors;

  truncateAt: number = 100;

  constructor() { }

  ngOnInit(): void {
  }

}
