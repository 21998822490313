<div class="row welcome">
    <div class="col-12 col-md-6">
        <h1>
            <span class="line1">We Love Jesus</span>
            <span class="line2">And Awesome Shirts</span>
          </h1>
    </div>
    <div class="col-12 col-md-6">
        <p>Get ready to rock faith-infused fashion with our favorite hand-selected and curated Christian tee collection! Dive into truth-telling tees that really hit home. We scout the best designs, mixing our own unique designs with awesome finds from other talented designers across the web.</p>
        <p>Every shirt, from catchy slogans to timeless messages, expresses boundless inspiration. Find your statement piece that vibes with your beliefs. This hand-selected collection blends faith and style for that extra dash of joy and good vibes. Come join the faith celebration in style!</p>
    </div>
</div>
<div class="row">
    <div col="col">
        <ul class="categories">
            <li *ngFor="let category of categories">
                <a class="{{selectedCategory.id == category.id ? 'active' : ''}}" (click)="onSelectCategory(category.id)">{{category.label}}</a>
            </li>
        </ul>
    </div>
</div>
<div class="row row-cols-3">
    <div class="col-12 col-md-4 p-4" *ngFor="let product of products">
        <product-card [product]="product"></product-card>
    </div>
</div>