import { ConvertActionBindingResult } from '@angular/compiler/src/compiler_util/expression_converter';
import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';
import { Products } from '../../data/products-const';
import { Category } from '../../models/category'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  products = Products;
  selectedCategory: Category;
  categories: Category[] = [
    {
      id: null,
      label: "All Categories"
    },
    {
      id: 1,
      label: "Women's"
    },
    {
      id: 2,
      label: "Men's"
    },
    {
      id: 3,
      label: "Children's"
    }
  ];

  constructor() { }

  ngOnInit(): void {

    this.selectedCategory = this.categories[0];
    this.onSelectCategory(this.selectedCategory.id)
  };

  onSelectCategory(id): void {
    // Reset Products
    this.products = this.shuffleProducts(Products);


    this.selectedCategory = this.categories.find(c => c.id == id);
    console.log("this.selectedCategory", this.selectedCategory);

    if (id !== null) {
      this.products = this.products.filter(p => p.categoryIds.includes(id));
    }

    console.table(this.products);

  }

  shuffleProducts(array: Product[]){
    return array.sort(() => Math.random() - 0.5);
  }

}
